.choose-container {
  margin-top: 5rem;
  height: 628px;
  background-image: url("../../../../public/img/ChooseBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.choose-login-btn {
  border: 1px solid #ffffff;
  border-radius: 25px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 600;
  color: #ffffff;
  width: 174px;
  height: 50px;
  background: none;
  margin-top: 15px;
  cursor: pointer;
}
.choose-title {
  font-size: 87.04px;
  font-family: "Fahkwang";
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  max-width: 845px;
}
.choose-descr {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-top: -25px;
}
.choose-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10rem;
}


@media (max-width: 988px) {
  .choose-container {
    padding: 3rem;
    margin-top: 0;
  }
  .choose-title {
    font-size: 40px;
  }

  .choose-descr {
    font-size: 18px;
    margin-top: -15px;
  }

  .choose-content {
    margin: 0;
    text-align: center;
  }

  .choose-login-btn {
    margin-top: 20px;
    max-width: 180px;
  }
}


@media (max-width: 768px) {
  .choose-container {
    height: auto;
    padding: 3rem;
  }

  .choose-title {
    font-size: 38px;
  }

  .choose-descr {
    font-size: 18px;
    margin-top: -15px;
  }

  .choose-content {
    margin: 0;
    text-align: center;
  }

  .choose-login-btn {
    margin-top: 20px;
    max-width: 160px;
  }
}

@media (max-width: 480px) {


  .choose-title {
    font-size: 36px;
    max-width: 90%;
  }

  .choose-descr {
    font-size: 16px;
    max-width: 90%;
    margin-top: -10px;
  }
  .choose-login-btn {
    margin-top: 20px;
    max-width: 120px;
    max-height: 40px;
  }
}