.footer-container {
  position: relative;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 324px;
}
.footer-email {

  margin-top: 30px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.email-link {
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 0.5rem;
}
.footer-copyright {
    margin-top: 30px;
    font-size: 14.7px;
font-family: "Roboto";
font-weight: 400;
color: rgba(221, 221, 221, 1);
}
.footer-social {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;
}
.footer-logo {
    margin-top: 38px;
}

@media(max-width: 500px) {
  .footer-container {
    height: 280px;
  }
}