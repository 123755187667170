.customer-header-container {
    padding: 2rem;
    background-image: url("../../../public/img/CustomerHeaderBg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #d9d9d9;
    min-height: 655px;
}

.age-container {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  justify-content: center;
  align-items: center;
}
/*Login page */

.login-container {
  display: flex;
  flex-direction: row;
}
.left-login-block {
  padding: 2rem;
  background-image: url("../../../public/img/Rectangle 41.png");
  height: 982px;
  width: 45%;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-title {
  font-size: 100px;
  font-family: "Fahkwang";
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.login-descr {
  font-size: 22px;
  font-family: "Roboto";
  font-weight: 300;
  max-width: 425px;
  margin: 0 auto;
  text-align: center;
}
.login-title-box {
  margin-top: 28vh;
}
.register-form-wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
}
.form-title {
  font-size: 60px;
  font-family: "Fahkwang";
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin: 0;
  text-align: left;
}
.form-descr {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  color: rgba(27, 27, 27, 1);
}
.form-item {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-left: 20px;
}
.input-field {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 340px;
  padding: 13px 16px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(17, 17, 19, 1);
}
.mandatory-field {
  border-color: red;
}
.input-label {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
}
.error-message {
  color: red;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: normal;
  margin-top: -5px;
  margin-bottom: 5px;
}
.register-form-btn {
  margin: 0 auto;
  margin-top: 35px;
  background-color: #000000;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 15px 38px;
  text-align: center;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  align-items: center;
  text-align: center;
  display: flex;
}
.register-form-btn.disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}
.login-line-box {
  margin-top: 17px;
  display: flex;
  width: 375px;
}
.horizontal-line {
  width: 100%;
  color: rgb(234, 229, 229);
}
.horizontal-line-txt {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 0 9px;
}
.red-bg {
  background-color: #d7503f;
  display: flex;
  justify-content: center;
  margin-top: 23px;
}
.google-icon {
  margin-right: 5px;
}
.popup-content {
    background-color: #FFFFFF;
    width: 657px;
    height: 540px;
}

  .popup-overlay  {
    background-color: #2F2F2F78;
  }
  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-subtitle {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }

 input[type=radio]:checked {
    accent-color: black;
 }
 .gender-label {
    font-weight: 400;
    font-family: "Roboto";
    font-size: 14px;
 }
 .gender-container {
    display: flex;
    column-gap: 24px;
 }

 .dropdown-list {
  padding: 10px 16px;
  width: 180px;
  height: 48px;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  color: rgba(115, 115, 115, 1);
 }

 @media(max-width: 1120px) {
  .login-title {
    font-size: 60px;
  }
  .login-descr {
    font-size: 18px;
  }

 }

 
 @media(max-width: 968px) {
  .form-title {
    font-size: 45px;
  }  
  .form-descr{
    font-size: 15px;
  } 
  .input-field {
    width: 280px;
  }
  .register-form-btn, .register-line-box {
    width: 314px;
  }
 }

  
 @media(max-width: 840px) {
  .form-title {
    font-size: 40px;
  }  
  .form-descr{
    font-size: 14px;
  } 
  .input-field {
    width: 240px;
  }
  .register-form-btn, .login-line-box {
    width: 274px;
  }
  .left-login-block {
    width: 200px;
  }
  .login-title {
    font-size: 40px;
    text-align: center;
  }  
  .login-descr{
    font-size: 14px;
  } 
  
 }

 @media(max-width: 585px) {
  .input-field {
    width: 260px;
  }
  .register-form-btn, .register-line-box {
    width: 294px;
  }
  .form-title {
    font-size: 30px;
  }  
  .form-descr{
    font-size: 12px;
  } 

  .login-title {
    font-size: 30px;
    text-align: center;
    margin-top: 0;
  }  
  .login-descr{
    font-size: 12px;
  } 
  .logo-txt {
    font-size: 0.5rem;
  }
  .login-container {
    height:100vh;
    flex-direction: column;
    align-items: center;
  }
  .left-login-block {
    height: 300px;
    width: 100%;
    padding: 0;
  }
  .register-form-wrapper {
    margin-top: 10px;
    padding-bottom: 100px;
  }
  .login-title-box {
    margin-top: 8vh;
    margin-bottom: 5vh;
  }
 }