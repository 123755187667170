.categorie-container {
    margin-top: 5rem;
}

.categorie-title {
    text-align: center;
    font-size: 50px;
    font-family: 'Fahkwang';
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
}

.parent {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 1px;
}

.div1 {
    grid-area: 1 / 1 / 2 / 2;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.div2 {
    grid-area: 2 / 1 / 3 / 2;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.div3 {
    grid-area: 1 / 2 / 3 / 3;
}
.categorie-mid-title {
    color: rgba(255, 255, 255, 1);
    font-size: 40px;
    font-family: 'Fahkwang';
    font-weight: 600;
    padding: 3rem 4rem;
    cursor: pointer;
}
.div4 {
    grid-area: 1 / 3 / 2 / 4;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.div5 {
    grid-area: 2 / 3 / 3 / 4;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mid-img {
    background-image: url('../../../../public/img/categorieMidImg.png');
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.categorie-txt {
    font-size: 30px;
    font-family: 'Fahkwang';
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}

.gray-bg {
    background-color: #efefef;
}
.categorie-img {
    width: 100%;
    height: 30vh;
}
@media (max-width: 968px) {
    .mid-img {
        
    }
    .categorie-txt {
        font-size: 20px;
    }
    .categorie-container {
      margin-top: 3rem;
    }
  
    .categorie-title {
      font-size: 36px;
    }
  
    .parent {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    .categorie-img {
        width: 100%;
        height: 15vh;
    }
  
    .div1,
    .div2,
    .div4,
    .div5 {
      padding: 1rem;
    }
  
    .categorie-mid-title {
      font-size: 30px;
      padding: 2rem;
    }
  
    
  }
  
  @media (max-width: 600px) {

    .parent {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0;
      position: relative;
    }
    .div3 {
      width: 90%;
      position: absolute;
      bottom: -145px;
      font-size: 20px;
    }
    .div1,
    .div2,
    .div4,
    .div5 {
      width: 240px;
      margin: 3px;
      background-color: white;
    }

  }

  @media (max-width: 480px) {
    .categorie-container {
      margin-top: 5rem;
    }
  
    .categorie-title {
      font-size: 30px;
    }
  
  }