.advantages-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 10rem;
}

.advantages-title {
    font-size: 50px;
    font-family: 'Fahkwang';
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-transform: uppercase;
}

.advantages-descr {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    color: rgba(14, 14, 14, 1);
    margin-top: -20px;
    text-align: center;
    display: block;
    max-width: 693px;
}
.advantages-boxes-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 15px;
}
.advantages-box {
    padding: 1rem;
    width: 22vw;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.advantages-box:hover {
    background-color: rgb(242, 242, 242);
}

.box-title {
    font-size: 24px;
    font-family: 'Fahkwang';
    font-weight: 700;
    color: rgba(14, 14, 14, 1);
    margin-top: 25px;
}
.box-txt {
    text-align: center;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    color: rgba(14, 14, 14, 1);
    margin-top: 15px;
}
.container {
    display: flex;
    flex-direction: row;
    padding: 8rem;
    justify-content: space-around;
}
.delivery-txt {
    display: flex;
    flex-direction: column;
}
.delivery-img {
    display: block;
    max-width: 100%;
    max-height: 888px;
}

.delivery-title {
    font-size: 40px;
    font-family: 'Fahkwang';
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    width: 384px;
    height: 104px;
}
.circle {
    border: 1px solid black;
    position: relative;
    width: 64px;
    height: 64px;
    line-height: 1.5em;
    border-radius: 100%;
    color: black;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
    font-size: 24px;
    font-family: 'Fahkwang';
    font-weight: 300;
    color: rgba(14, 14, 14, 1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dotted-line {
    height: 100px;
    border-left: 1px dashed black;
    margin-left: 32px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.title {
    font-size: 24px;
    font-family: 'Fahkwang';
    font-weight: 700;
    color: rgba(14, 14, 14, 1);
    max-width: 274px;
    margin-bottom: 1rem;
}
.caption {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    color: rgba(14, 14, 14, 1);
    max-width: 215px;
    font-style: normal;
    margin-top: -12px;
}
.list-content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
.list-item {
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
}

@media(max-width: 1050px) {
  .delivery-img {
    max-width: 350px;
    height: 100%;
    margin-top: 200px;
  }

  .container {
    padding-bottom: 0;
  }
}

  @media (max-width: 768px) {
    .advantages-title, .delivery-title {
      font-size: 36px;
    }
    .advantages-boxes-wrapper {
      display: flex;
      justify-content: center;

    }
    .advantages-box {
      width: 50vw;
      margin: 1rem;
    }
    .container {
      flex-wrap: wrap;
    }
    .delivery-img {
      margin-top: 2rem;
      align-self: center;
    }
    .advantages-descr {
      font-size: 16px;
    }
  }

@media (max-width: 500px) {
  .advantages-title {
    margin-top: 60px;
  }
    .advantages-wrapper {
      padding: 0;
    }
    .delivery-img {
      display: none;

    }
    .delivery-title {
      font-size: 30px;
    }
    .advantages-title, .delivery-title {
      font-size: 30px;
    }
  }
