.admin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


table th, table td {
    border: 1px solid #ddd; 
    padding: 8px;
    text-align: left;
}
